import { 
    Dialog,
    Container,
    Box,
    Typography,
    Button
} from "@material-ui/core"

const ConfirmationModal = (props) =>{
    const {handleClose, open, handleConfirm, title, description, cancleButtonName, confirmButtonName, primaryColor } = props
    return(
        <Dialog
              open={open}
              onClose={() => handleClose()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                zIndex:1320,
              }}
            >
              <Container maxWidth="sm" sx={{display: 'flex',flexDirection: 'column',justifyContent: 'space-evenly',height:'200px'}}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection:'column',
                  }}
                >
                      <Typography
                        color="textPrimary"
                        variant="h4"
                      >
                      {/* Log out */}
                      {title}
                      </Typography>
                </Box>
                <Box>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        {/* Are you sure you want to log out ? */}
                        {description}
                      </Typography>
                    </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    // sx={{ mr: 2 }}
                    variant="outlined"
                    onClick={() => handleClose()} color="error">
                    {/* Cancel */}
                    {cancleButtonName}
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: `${primaryColor?'':'error.main'}`,
                      '&:hover': {
                        backgroundColor: `${primaryColor?'':'error.main'}`
                      },
                      ml:2
                    }}
                    variant="contained"
                    onClick={() => handleConfirm()} color="primary" autoFocus >
                    {/* Log out */}
                    {confirmButtonName}
                  </Button>
                </Box>
              </Container>
            </Dialog>
    )
}

export default ConfirmationModal