import axios from "axios";
import { baseURL } from "../Environment";

const ApiRequest = axios.create({
    baseURL,
    headers: {
        'Authorization' : `Bearer ${localStorage.getItem('Token')}`
    }
});

const responseHandler = response => {
    return response;
}

const errorHandler = error => {
    // console.log(error.response);
    return Promise.reject(error);
} 

ApiRequest.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
)

ApiRequest.interceptors.request.use(
    config => {
        const token = localStorage.getItem('Token')
        config.headers.Authorization = `Bearer ${token}`;
        return config
    },
    error => Promise.reject(error)
)

export default ApiRequest;