import ApiRequest from './ApiRequest';
import requestUrls from '../Environment';

const dummyPackages = [
    
]
export async function getPackages(){
    try{
        const response = await ApiRequest.get(requestUrls.getPackages);
        return Promise.resolve(response.data.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function getPackageApiRequest(packageId){
    try{
        const response = await ApiRequest.get(requestUrls.getPackage+`${packageId}`);
        return Promise.resolve(response.data.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function getServices(){
    try{
        const response = await ApiRequest.get(requestUrls.getServices);
        return Promise.resolve(response.data.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function addPackage(data){
    try{
        const response = await ApiRequest.post(requestUrls.addPackage,data);
        return Promise.resolve(response.data.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function updatePackageApiRequest(packageId, data){
    try{
        const response = await ApiRequest.put(requestUrls.updatePackage + `${packageId}`, data)
        return Promise.resolve(response.data.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function deletePackageApiRequest(packageId){
    try{
        const response = await ApiRequest.delete(requestUrls.deletePackage+`${packageId}`)
        return Promise.resolve(response);
    }catch(error){
        return Promise.reject(error)
    }
}