import { Navigate } from "react-router-dom";

const AuthGuard = (props) =>{
    const token = localStorage.getItem('Token');
    const {children}=props
    if(!token){
        return <Navigate to="/login"></Navigate>
    }
    else{
        return children
    }
}

export default AuthGuard