// export const baseURL = "http://localhost:8080";
// export const baseURL = "http://appserver.legalandtax.co.za:8081";
export const baseURL = "https://appapi.solacebenefits.co.za";

export const requestUrls = {
    getAllUser:`/admin/users`,
    getUser:`/user/get/`,
    getAllCompany:`/admin/companies`,
    getPackages:`/admin/packages`,
    getPackage:`/admin/package/get/`,
    getCompany:`/admin/company/get/`,
    addCompany:`/admin/company/create`,
    deleteUser:`/user/delete/`,
    deleteCompany:`/admin/company/delete/`,
    deletePackage:`/admin/package/delete/`,
    getServices:`/services`,
    updateCompany:`/admin/company/update/`,
    uploadCsv:`admin/upload`,
    addUser:`/user/create`,
    login:`${baseURL}/admin/login`,
    changePassword:`/admin/update-password`,
    addPackage:`/admin/package/create`,
    updatePackage:`/admin/package/update/`,
    dashboard:`/admin/dashboard`,
    pushNotification:`/admin/push-notification`,
    updateUser:`/user/update/`
}

export default requestUrls
export const REACT_APP_ENABLE_REDUX_DEV_TOOLS=true