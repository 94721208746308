import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider, 
  Drawer,
  IconButton,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SidebarNavSection from './SidebarNavSection';
import Scrollbar from '../Scrollbar';
import ChartSquareBarIcon from '../../../icon/ChartSquareBar';
import UserIcon from '../../../icon/Users';
import SolaceIcon from '../../../assets/images/SolaceIcon.svg';
import { Logout } from '@material-ui/icons';
import { LockReset } from '@mui/icons-material';
import ConfirmationModal from '../modal/ConfirmationModal';
import Briefcase from '../../../icon/Briefcase';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
// put logo for mobile view

const sections = [
  {
    title:'1',
    items: [
      {
        key:1,
        title: 'Dashboard',
        path: '/',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
          key:2,
          title: 'User Management',
          path:'/user-management',
          icon: <UserIcon fontSize="small" />
      },
      {
          key:3,
          title:'Company Management',
          path:'/company-management',
          icon: <Briefcase fontSize="small" />
      },
      {
          key:4,
          title:'Package Management',
          path:'/package-management',
          icon: <WorkspacesIcon fontSize='small' />
      },
      {
          key:5,
          title:'Push Notification',
          path:'/push-notification',
          icon: <NotificationsIcon fontSize='small' />
      }
    ]
  },
];

// limited accesss
// const filterSection=(arr)=>{
//   let sectionfilter = sections[0].items.filter((item)=>arr.includes(item.key))
//   sections[0].items=sectionfilter
// }

const SideBar = (props) => {
  // let section ={title:'1',items:[]}
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const navigate = useNavigate();

// limited access
//   useEffect(()=>{
//     filterSection([1,2,3,4,5,6,7,8,9]);
//   },[])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleConfirmationModalClose =()=>{
    setOpenConfirmationModal(false);
  }

  const handleLogout = () =>{
    localStorage.clear();
    navigate('/login');
    setOpenConfirmationModal(false);
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // backgroundColor:'primary.mainGradient',
        // backgroundImage:'linear-gradient(201deg, rgba(76,175,225,1) 0%, rgba(0,103,162,1) 100%)'
        backgroundImage:'linear-gradient(201deg, rgba(0,103,162,1) 0%, rgba(76,175,225,1) 100%)'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {/* Change for mobile view side bar */}
        {/* <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
          backgroundColor="primary.main"
        > 
          <Box sx={{height: '90px'}}>
            <RouterLink to="/">
            <Avatar
            // put logo for mobile view
              src={''}
              sx={{
                height: 40,
                width: 40
              }}
            /> 
            </RouterLink>
          </Box>
        </Box> */}
        <Box sx={{ p: 2,pb:0 }}>
          <Box
            sx={{
              alignItems: 'center',
              // backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              flexDirection:'column',
              overflow: 'hidden',
              p: 2,
              pb:0
            }}
          >
            <RouterLink to="/">
              {/* <Avatar
                src={SolaceIcon}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              /> */}
              <img src={SolaceIcon} />
            </RouterLink>
            <Box
              sx={{
                mt:1
              }}
            >
              <IconButton
              component={RouterLink}
               to={'/change-password'}
              >
                <LockReset
                  sx={{
                    color:'#fff'
                  }}
                />
              </IconButton>
              <IconButton
                onClick={()=>{setOpenConfirmationModal(true)}}
              >
                <Logout
                  sx={{
                    color:'#fff'
                  }}
                />
              </IconButton>
              <ConfirmationModal open = {openConfirmationModal} handleClose={handleConfirmationModalClose} handleConfirm={handleLogout} title={'Log out'} description={'Are you sure you want to log out ?'} cancleButtonName={'Cancel'} confirmButtonName={'Log out'}/>
            </Box>
            {/* Change for side bar name and email */}
            {/* <Box>
              <Typography
                color="textPrimary"
                variant='body1'
                align='center'
              >
                {userName}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {userEmail}
              </Typography>
            </Box> */}
          </Box>
        </Box>
        <Divider sx={{bgcolor:'#fff'}}/>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <SidebarNavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            // height: 'calc(100% - 64px) !important', // Change_remove navbar
            // top: '64px !Important', // Change_remove navbar
            height: '100% !important',
            top:'0',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default SideBar;
