import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    responseService:[],
    responsePackages:[]
  };

const packageSlice = createSlice({
    name:'packageSlice',
    initialState,
    reducers:{
            setService(state,action){
              const data = action.payload
              let subServiceId = []
              state.responseService = data.map(item => {
                if(item.subServices){
                  subServiceId = item.subServices.map((subService)=>subService.id)
                }
                // if(!family) family = []
                item = {...item,subServiceId}
                return item
              });
            },
            setPackages(state,action){
              const data = action.payload
              state.responsePackages = data;
            },
            addInsertedPackage(state,action){
              state.responsePackages = [...state.responsePackages ,action.payload]
            },
            updatePackage(state,action){
              let data = action.payload
              state.responsePackages = state.responsePackages.map((item)=>{
                let tempPackage = {...item}
                if(tempPackage.id === data.id){
                  tempPackage.services = data.services
                  tempPackage.name = data.name
                }
                return tempPackage
              })
            },
            deletePackage(state,action){
              state.responsePackages = state.responsePackages.filter(item=> item.id !== action.payload)
            }
    }
});

export const {reducer} = packageSlice
export const packageActions = packageSlice.actions;

export default packageSlice