import { useEffect } from 'react';
import CommonLoader from "./shared/components/CommonLoader";
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import SettingsDrawer from './shared/components/SettingsDrawer';
import useSettings from './hooks/useSettings';
// import useMounted from './hooks/useMounted';
import { createCustomTheme } from './theme';
import { useRoutes } from "react-router-dom";
import routes from './router'
import CommonToaster from "./shared/components/Toaster";
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import { getAllCompany } from './api/CompanyApi';
import { getAllUsers } from './api/UserApi';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { CUDataActions } from './store/slices/CUData';
import { getServices } from './api/PackagesApi';
import { packageActions } from './store/slices/Package';
import { getPackages } from './api/PackagesApi';
import { loadingActions } from './store/slices/loading';

function App() {
  const content = useRoutes(routes);
  const token = localStorage.getItem('Token');
  const dispatch = useDispatch();
  const { settings } = useSettings();
  const isLoading = useSelector(state=>state.loading.counter);
  const theme = createCustomTheme({
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });
  const responseUsers = useSelector((state)=>state.CUData.responseUsers)
  const responseCompany = useSelector((state)=>state.CUData.responseCompany)
  

  useEffect(()=>{
    if(token){
    const getCompanies = async()=>{
      try{
        dispatch(loadingActions.increment());
        const response = await getAllCompany();
        dispatch(CUDataActions.getCompany(response));
      }catch(error){
        console.log(error);
        toast.error("Something went wrong!")
      }finally{
        dispatch(loadingActions.decrement());
      }
    }

    const getUsers = async()=>{
      try{
        dispatch(loadingActions.increment());
        const response = await getAllUsers();
        dispatch(CUDataActions.getUser(response))
      }catch(error){
        console.log(error);
        toast.error("Something went wrong!")
      }finally{
        dispatch(loadingActions.decrement());
      }
    }

    const getAllService = async()=>{
      try{
        dispatch(loadingActions.increment());
        const response = await getServices();
        dispatch(packageActions.setService(response))
      }catch(error){
        console.log(error);
        toast.error("Something went wrong!")
      }finally{
        dispatch(loadingActions.decrement());
      }
    }

    const getAllPackages= async () => {
      try {
          dispatch(loadingActions.increment());
          const data = await getPackages();
          dispatch(packageActions.setPackages(data));
      }catch (err) {
          console.error(err);
      }finally{
          dispatch(loadingActions.decrement());
      }
    }

   
      getAllService()
      getCompanies()
      getUsers()
      getAllPackages()
    }
  },[token])
  useEffect(()=>{
    dispatch(CUDataActions.addEmployeeCompany());
  },[responseUsers,responseCompany])
  return (
    <>
      <ThemeProvider theme={theme}>
          <SettingsDrawer />
          <CommonToaster />
          <CssBaseline/>
            {content}
      </ThemeProvider>
      {isLoading>0 && <CommonLoader/>}
    </>
  );
}

export default App;
