import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import { SettingsProvider } from './context/SettingsContext';
import { Provider } from 'react-redux';
import store from './store';

ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);