import ApiRequest from './ApiRequest';
import requestUrls from '../Environment';


export async function getAllUsers(){
    try{
        const response = await ApiRequest.get(requestUrls.getAllUser);
        return Promise.resolve(response.data.data)
    }catch(error){
        return Promise.reject(error);
    }
}

export async function getUser(id){
    try{
        const response = await ApiRequest.get(requestUrls.getUser+`${id}`);
        return Promise.resolve(response.data.data)
    }catch(error){
        return Promise.reject(error)
    }
}

export async function addUser(userDetails){
    try{
        const response = await ApiRequest.post(requestUrls.addUser,userDetails)
        return Promise.resolve(response.data.data)
    }catch(error){
        return Promise.reject(error)
    }
}

export async function deleteUserApiRequest(userId){
    try{
        const response = await ApiRequest.delete(requestUrls.deleteUser +`${userId}`)
        return Promise.resolve(response);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function updateUserApiRequest(id,data){
    try{
        const response = await ApiRequest.put(requestUrls.updateUser+`${id}`,data)
        return Promise.resolve(response.data.data)
    }catch(error){
        return Promise.reject(error)
    }
}