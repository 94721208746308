import { combineReducers } from '@reduxjs/toolkit';
import {reducer as loadingReducer} from './slices/loading';
import { reducer as CUDataReducer} from './slices/CUData';
import {reducer as PackageReducer} from './slices/Package';
import { reducer as AuthReducer} from './slices/AuthSlice';

const rootReducer = combineReducers({
  loading:loadingReducer,
  CUData:CUDataReducer,
  packageSlice:PackageReducer,
  auth:AuthReducer
});
  
export default rootReducer;