import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    responseCompany:[],
    responseUsers:[],
    users:{},
    companies:{}
  };

const CUDataSlice = createSlice({
    name:'CUData',
    initialState,
    reducers:{
        getCompany(state,action){
            let data = action.payload;
            state.responseCompany = data;
        },
        getUser(state,action){
            let data = action.payload;
            state.responseUsers = data
            let newUser = {}
            let family =[]
            data.forEach(item => {
                family = data.filter((user)=> !user.isPrimaryUser && user.primaryUserId === item.userId).map(x=>x.userId)
                // if(!family) family = []
                newUser[item.userId] = {...item,family}
            });
            state.users = newUser
        },
        addEmployeeCompany(state,action){
            let newCompany = {}
            let employee = []
            let totalEmployee
            let totalMembers
            state.responseCompany.forEach(company =>{
                employee = state.responseUsers.filter((user)=>(user.isPrimaryUser && user.companyId === company.id)).map(x=>x.userId)
                // if(!employee) employee =[]
                totalEmployee = employee.length
                totalMembers = state.responseUsers.filter((user)=>(user.companyId === company.id)).map(x=>x.userId).length
                newCompany[company.id] = {...company,employee,totalMembers,totalEmployee}
            })
            state.companies = newCompany
        },
        updateUser(state,action){
            let data = action.payload.data
            let oldData = action.payload.details
            state.users[data.userId] = { ...state.users[data.userId],...data }
            if(data.companyId != oldData.companyId){
                // User in new compnay 
                let totalMembers = state.companies[data.companyId].totalMembers + (state.users[data.userId].family).length + 1
                let totalEmployee = state.companies[data.companyId].totalEmployee + 1
                let employee = state.companies[data.companyId].employee.map(x=>x);
                employee.push(data.userId)
                state.companies[data.companyId] = { ...state.companies[data.companyId],employee,totalMembers,totalEmployee}

                // Remove user from old company
                totalMembers = state.companies[oldData.companyId].totalMembers - (state.users[data.userId].family).length - 1
                totalEmployee = state.companies[oldData.companyId].totalEmployee - 1
                employee = state.companies[oldData.companyId].employee.filter(x=>x != data.userId);
                state.companies[oldData.companyId] = { ...state.companies[oldData.companyId],employee,totalMembers,totalEmployee}
            }
        }
    }
});

export const {reducer} = CUDataSlice
export const CUDataActions = CUDataSlice.actions;

export default CUDataSlice