import ApiRequest from './ApiRequest';
import requestUrls from '../Environment';

const dummyCompany = {
    message:"Companies fetched successfully.",
    data:[
        {
            id:5,
            name:"company1",
            packageId:null,
            isActive:true
        },
        {
            id:6,
            name:"company2",
            packageId:7,
            isActive:true
        },
        {
            id:7,
            name:"company2",
            packageId:null,
            isActive:true
        }
    ]
}
export async function getAllCompany(){
    try{
        const response = await ApiRequest.get(requestUrls.getAllCompany);
        return Promise.resolve(response.data.data)
    }catch(error){
        return Promise.reject(error);
    }
}

export async function getCompany(id){
    try{
        const response = await ApiRequest.get(requestUrls.getCompany+`${id}`)
        return Promise.resolve(response.data.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function addCompany(companyName , selectedPackageId){
    try{
        const response = await ApiRequest.post(requestUrls.addCompany,{name:companyName,packageId:selectedPackageId})
        return Promise.resolve(response.data.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function updateCompany(companyId, companyName , selectedPackageId ,isActive){
    try{
        const response = await ApiRequest.put(requestUrls.updateCompany+`${companyId}`,{name:companyName,packageId:selectedPackageId,isActive:isActive})
        return Promise.resolve(response.data.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export async function uploadCsv(path){
    try{
        const response = await ApiRequest.post(requestUrls.uploadCsv,path,{headers:{
            'Content-Type':'multipart/form-data'
        }})
        return Promise.resolve(response)
    }catch(error){
        return Promise.reject(error)
    }
}

export async function deleteCompanyApiRequest(companyId){
    try{
        const response = await ApiRequest.delete(requestUrls.deleteCompany+`${companyId}`)
        return Promise.resolve(response)
    }catch(error){
        return Promise.reject(error)
    }
}