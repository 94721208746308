import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Token:localStorage.getItem('Token')
};

const AuthSlice = createSlice({
    name:'CUData',
    initialState,
    reducers:{
        setToken(state,action){
            state.Token = action.payload
        }
    }
});

export const {reducer} = AuthSlice
export const AuthActions = AuthSlice.actions;

export default AuthSlice