import PageLayout from "./shared/components/layouts/Pagelayout";
import { Suspense, lazy } from 'react';
import AuthGuard from "./guards/AuthGuard";
// import { Navigate } from 'react-router-dom';


const Loadable = (Component) => (props) => (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );

// const LoginPage = Loadable(lazy(()=> import('./pages/LoginPage')));
const Dashboard = Loadable(lazy(()=>import('./pages/dashboard/Dashboard')));

const UserManagement = Loadable(lazy(()=>import('./pages/user/UserManagement')));
const UpdateUser = Loadable(lazy(()=>import('./pages/user/UpdateUser')));
const UserDetail = Loadable(lazy(()=>import('./pages/user/UserDetail')));

const CompanyManagement = Loadable(lazy(()=>import('./pages/company/CompanyManagement')));
const CompanyDetail = Loadable(lazy(()=> import('./pages/company/CompanyDetail')));
const AddCompany = Loadable(lazy(()=>import('./pages/company/AddCompany')));

const PackageManagement = Loadable(lazy(()=>import('./pages/packages/PackageManagement')));
const AddPackage = Loadable(lazy(()=>import('./pages/packages/AddPackage')));
const UpdatePackage = Loadable(lazy(()=> import('./pages/packages/UpdatePackage')));

const Login = Loadable(lazy(()=>import('./pages/Login')));
const ChangePassword = Loadable(lazy(()=>import('./pages/ChangePassword')));

const PushNotification = Loadable(lazy(()=>import('./pages/pushnotification/PushNotification')));

const routes = [
    {
        path:'',
        element:<AuthGuard><PageLayout /></AuthGuard>,
        children:[
            {
                path:'/',
                element: <Dashboard key={1}/>
            },
            {
                path:'/user-management',
                children:[
                    {
                        path:'',
                        element: <UserManagement />
                    },
                    {
                        path:'update/:id',
                        element: <UpdateUser />
                    },
                    {
                        path:'detail/:id',
                        element: <UserDetail />
                    }
                ]
            },
            {
                path:'/company-management',
                children:[
                    {
                        path:'',
                        element: <CompanyManagement />
                    },
                    {
                        path:'detail/:id',
                        element: <CompanyDetail />
                    },
                    {
                        path:'add-company',
                        element: <AddCompany />
                    }
                ]
            },
            {
                path:'/package-management',
                children:[
                    {
                        path:'',
                        element:<PackageManagement />
                    },
                    {
                        path:'add-package',
                        element:<AddPackage />
                    },
                    {
                        path:'update-package/:id',
                        element:<UpdatePackage />
                    }
                ]
            },
            {
                path:'/push-notification',
                element:<PushNotification />
            }
        ]
    },
    {
        path:'/login',
        element:<Login />
    },
    {
        path:'/change-password',
        element: <AuthGuard><ChangePassword /></AuthGuard>
    }
]

export default routes