import Loader from "react-loader-spinner";

const CommonLoader = ()=>{
    return (
    <>
        <div 
            style={{
                display: 'flex',
                width: '100vw',
                zIndex: 9999,
                backgroundColor: '#00549250',
                justifyContent: 'center',
                alignItems: 'center',
                height: "100vh",
                top: 0,
                left: 0,
                position: 'absolute'}}
        >
            <Loader type="Watch" color="#005492" height={100} weight={100}/>
        </div>
    </>
    )
}

export default CommonLoader